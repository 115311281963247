import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa'; 
import { Link } from 'react-router-dom';

function Login({ onClose }) {
  const [isSignup, setIsSignup] = useState(false);

  return (
    <div className="login-popup">
      <div className="popup-header">
        <h2>{isSignup ? 'Sign Up' : 'Login'}</h2>
        <FaTimes 
          onClick={onClose} 
          style={{ cursor: 'pointer', fontSize: '24px' }} 
        />
      </div>
      {isSignup ? (
        <Signup onSwitch={() => setIsSignup(false)} />
      ) : (
        <LoginForm onSwitch={() => setIsSignup(true)} />
      )}
    </div>
  );
}

function LoginForm({ onSwitch }) {
  return (
    <form>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" className="form-control" />
      </div>
      <div className="form-group">
        <label>Password:</label>
        <input type="password" className="form-control" />
      </div>
      <div className="forgot-password mb-3">
        <a href="#">Forgot password?</a>
      </div>
      <button type="submit" className="btn btn-primary" style={{ padding: '10px 162px', borderRadius: '23px' }}>Login</button>
      <p className="text-center mt-1">
        Don't have an account? <Link onClick={onSwitch} style={{textDecoration: 'none'}}>Register</Link>
      </p>
    </form>
  );
}

function Signup({ onSwitch }) {
  return (
    <form>
      <div className="form-group">
        <label>Username:</label>
        <input type="text" className="form-control" />
      </div>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" className="form-control" />
      </div>
      <div className="form-group">
        <label>Password:</label>
        <input type="password" className="form-control" />
      </div>
      <button type="submit" className="btn btn-primary" style={{ padding: '10px 150px', borderRadius: '23px' }}>Register</button>
      <p className="text-center mt-1">
        Already have an account? <Link  onClick={onSwitch} style={{textDecoration: 'none'}}>Login</Link>
      </p>
    </form>
  );
}

export default Login;
 