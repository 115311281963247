import React from 'react';

export default function DownloadApp() {
  return (
    <div className="container m-5 text-center fw-bold p-5">
      <div className="row">
          <div className="col-12">
                <h2 className='fw-bold mb-4'>Download the App Now!</h2>
                <button type="button" class="btn btn-primary">Download App</button>
              
          </div>
      </div>
    </div>
  )
}
