import React, { useState } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form submission logic here

    // Set form submission status to true
    setFormSubmitted(true);

    // Clear the form fields
    setFormData({
      name: '',
      email: '',
      message: ''
    });

    // Remove the success message after 1 seconds
    setTimeout(() => {
      setFormSubmitted(false);
    }, 1000);
  };

  return (
    <div>
      <Header />
      <main className="main">
        <div className="contact"></div>
        <section id="contact" className="contact-section mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 contact-details">
                <h2 className="mb-2">Get In Touch</h2>
                <p className="mb-4" style={{fontSize: '20px'}}>
                  Send us a message and we will get back to you!
                </p>
                <h5 className="mb-1">
                  <b>Email:</b>
                  <br />
                </h5>
                <p>contactus@ivalk.com</p>
                <h5>
                  <b>Phone:</b>
                  <br />
                </h5>
                <p>+91-123-456-7890</p>
              </div>

              <div className="col-lg-6 contact-form">
                <h2>Send a Message</h2>
                {formSubmitted && (
                  <div className="alert alert-success" role="alert">
                    Form submitted successfully!
                  </div>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input 
                      type="text" 
                      id="name" 
                      name="name" 
                      className="form-control" 
                      value={formData.name}
                      onChange={handleChange}
                      required 
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input 
                      type="email" 
                      id="email" 
                      name="email" 
                      className="form-control" 
                      value={formData.email}
                      onChange={handleChange}
                      required 
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea 
                      id="message" 
                      name="message" 
                      className="form-control" 
                      rows="4" 
                      value={formData.message}
                      onChange={handleChange}
                      required 
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
