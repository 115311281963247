import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Aboutus from './pages/Aboutus';
import Technology from './pages/Technology';


function App() {
  return (
    <Router>
      <Routes>
      <Route exast path="/" element={<Home />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Aboutus" element={<Aboutus />} />
      <Route path="/Technology" element={<Technology />} />
   </Routes>
   </Router>
  );
}

export default App;
