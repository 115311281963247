import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import icon from '../assets/icon1.png';
import Login from '../pages/Login';
import Signup from '../pages/Signup';

export default function Header() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupType, setPopupType] = useState('login');

  const handleUserIconClick = (type) => {
    setPopupType(type);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light px-0 py-2">
        <div className="container-xl">
          <a className="navbar-brand" href="/">
            <img src={icon} alt="..." style={{ height: '55px' }} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto">
              <Link className="nav-item nav-link active" to="/" aria-current="page">
                Home
              </Link>
              <Link className="nav-item nav-link" to="/Aboutus">
                About Us
              </Link>
              <Link className="nav-item nav-link" to="/Technology">
                Technology
              </Link>
              <Link className="nav-item nav-link" to="/Contact">
                Contact
              </Link>
              <button
                className="nav-item nav-link bg-transparent border-0"
                style={{ color: 'black' }}
                onClick={() => handleUserIconClick('login')}
              >
                <FaUser style={{ fontSize: '15px', verticalAlign: 'middle' }} />
              </button>
            </div>
          </div>
        </div>
      </nav>

      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            {popupType === 'login' ? (
              <Login onClose={closePopup} />
            ) : (
              <Signup onClose={closePopup} />
            )}
          </div>
        </div>
      )}
    </>
  );
}
