import React from 'react';
import Header from '../component/Header';
import HomeBackground from '../component/HomeBackground';
// import Affiliatecard from '../component/Affiliatecard';
// import ImageCard from '../component/ImageCard';
import Pricingcard from '../component/Pricingcard';
import DownloadApp from '../component/DownloadApp';
import Footer from '../component/Footer';
import AboutText from '../component/AboutText';

export default function Home() {
  return (
    <div>
   <Header />
   <HomeBackground />
   {/* <Affiliatecard /> */}
   <AboutText />
   {/* <ImageCard /> */}
   <Pricingcard />
   <DownloadApp />
   <Footer />
   </div>
  );
}
