import React from 'react';
import Computer from '../assets/Computer.png';
import Object from '../assets/Object.png';
import Alert from '../assets/Alert.png';

export default function Affiliatecard() {
  return (
    <div class="container">
        <h2 className='mt-5 mb-5 text-center fw-bold'>Artificial Intelligence and Computer Vision</h2>
    
    <div class="card-deck row">
    
      <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="card">
        <div class="view overlay">
          <img class="card-img-top" src={Computer}/>
          <a href="#!">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
      </div>
  
    <div class="col-xs-12 col-sm-6 col-md-4">  
      <div class="card mb-4">
        <div class="view overlay">
          <img class="card-img-top" src={Object}/>
          <a href="#!">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
    </div>
    
    <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="card mb-4">
        <div class="view overlay">
          <img class="card-img-top" src={Alert}/>
          <a href="#!">
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
      </div>
    </div>  
    
      
    </div>
  
      
    </div>
  )
}
