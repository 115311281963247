import React, { useState } from 'react'; // Import useState
import Login from '../pages/Login';
import Signup from '../pages/Signup';

export default function Pricingcard() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupType, setPopupType] = useState('login');

  const handleUserIconClick = (type) => {
    setPopupType(type);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="container mt-5">
      <h2 className='mt-5 mb-5 text-center'>Pricing Plans</h2>
      <div className="row">
        <div className="col-lg-4 col-md-6 mb-3">
          <div className="card py-4 px-lg-5 h-100" style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
            <div className="card-body d-flex flex-column">
              <div className="card-title text-center fs-2 fw-bold">Free</div>
              <div className="text-center mb-4">
                {/* <span className="fw-bold fs-2">$7</span>/month */}
              </div>
              <div className="pricing">
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <i className="fas fa-check-circle icon-color"></i>
                    <span className="small ms-3">120 hours of app access per month</span>
                  </li>
                  <li className="mb-3">
                    <i className="fas fa-check-circle icon-color"></i>
                    <span className="small ms-3">No Analytics access</span>
                  </li>
                </ul>
              </div>
              <div className="mt-auto text-center">
                <button type="button" className="btn btncolor btn-custom" onClick={() => handleUserIconClick('login')}>Choose Plan</button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mb-3">
          <div className="card py-4 px-lg-5 h-100" style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
            <div className="card-body d-flex flex-column">
              {/* <p className='text-center'>Most Popular</p> */}
              <div className="card-title text-center fs-2 fw-bold">Standard</div>
              <div className="text-center mb-4">
                <span className="fw-bold fs-2">$1</span>/month
              </div>
              <div className="pricing">
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <i className="fas fa-check-circle icon-color"></i>
                    <span className="small ms-3">Unlimited usage</span>
                  </li>
                  <li className="mb-3">
                    <i className="fas fa-check-circle icon-color"></i>
                    <span className="small ms-3">Analytics display in app</span>
                  </li>
                  <li className="mb-3">
                    <i className="fas fa-check-circle icon-color"></i>
                    <span className="small ms-3">Active support and feedback</span>
                  </li>
                </ul>
              </div>
              <div className="mt-auto text-center">
                <button type="button" className="btn btncolor btn-custom" onClick={() => handleUserIconClick('login')}>Choose Plan</button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mb-3">
          <div className="card py-4 px-lg-5 h-100" style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
            <div className="card-body d-flex flex-column">
              <div className="card-title text-center fs-2 fw-bold">Enterprise</div>
              <div className="text-center mb-4">
                {/* <span className="fw-bold fs-2">$15</span>/month */}
              </div>
              <div className="pricing">
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <i className="fas fa-check-circle icon-color"></i>
                    <span className="small ms-3">To Know More Mail Us At:</span>
                    <p className="text-center mt-2"><a href="mailto:sales@ivalk.com" style={{textDecoration: 'none'}}>sales@ivalk.com</a></p>
                  </li>
                </ul>
              </div>
              <div className="mt-auto text-center">
                <button type="button" className="btn btncolor btn-custom" onClick={() => handleUserIconClick('login')}>Choose Plan</button>
              </div>
            </div>
          </div>
        </div>

        {isPopupOpen && (
          <div className="popup-overlay" onClick={closePopup}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              {popupType === 'login' ? (
                <Login onClose={closePopup} />
              ) : (
                <Signup onClose={closePopup} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
