import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';

export default function Aboutus() {
  return (
    <div>
      <Header />
      
      <div className="aboutus">
        <div className="about-text">
          <h3>About IValk</h3>
          <h5>*Vision :</h5>
          <p>
          To revolutionize mobility by harnessing advanced computer vision technology,
          ensuring that everyone can navigate their environment with confidence, safety, and ease.
          </p>
          <h5>*Mission :</h5>
          <p>To enhance safety and accessibility through innovative technology,
transforming smartphones/automobiles into powerful tools for real-time obstacle
detection and navigation assistance, making walking safer for everyone, everywhere.</p>
        </div>
      </div>

      <section id="about" className="about section" style={{ padding: 0 }}>
        <div className="container">
          <div className="row gx-0 m-5">
            <div className="col-lg-12">
              <div className="content" style={{ backgroundColor: 'white' }}>
                <h2 style={{ textAlign: 'center', fontWeight: 700 }}>Our Story</h2>
                <p style={{ textAlign: 'center' }}>
                  IValk was founded by a team of innovators who recognized<br />
                  the potential of computer vision and machine learning to<br />
                  transform the way we live and work. With a passion for<br />
                  pushing the boundaries of what is possible, we set out to<br />
                  create a product that would make a tangible difference in<br />
                  people's lives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about section" style={{ padding: 0 }}>
        <div className="container">
          <div className="row gx-0 m-5">
            <div className="col-lg-12">
              <div className="content" style={{ backgroundColor: 'white' }}>
                <h2 style={{ textAlign: 'center', fontWeight: 700 }}>Our Technology</h2>
                <p style={{ textAlign: 'center' }}>
                  IValk's flagship product is an AI-powered object detection<br />
                  system that uses computer vision and machine learning<br />
                  algorithms to identify and alert users to potential hazards or<br />
                  obstacles in their environment. Our technology is designed<br />
                  to be intuitive, user-friendly, and highly accurate, making it<br />
                  an essential tool for anyone who wants to stay safe and<br />
                  aware in today's fast-paced world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about section" style={{ padding: 0 }}>
        <div className="container">
          <div className="row gx-0 m-5">
            <div className="col-lg-12">
              <div className="content" style={{ backgroundColor: 'white' }}>
                <h2 style={{ textAlign: 'center', fontWeight: 700 }}>Our Team</h2>
                <p style={{ textAlign: 'center' }}>
                  Our team is comprised of experts in computer vision,<br />
                  machine learning, and software development, with a shared<br />
                  commitment to innovation and excellence. We are passionate<br />
                  about creating products that make a positive impact on<br />
                  people's lives and are dedicated to delivering the highest<br />
                  level of quality and customer satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
}
