import React from 'react';
import Header from '../component/Header';
import Affiliatecard from '../component/Affiliatecard';
// import ImageCard from '../component/ImageCard';
import Footer from '../component/Footer';
// import About from '../assets/about.png';

export default function Technology() {
  return (
    <div>
      <Header />
      <div className="header">
         <div className="header-text text-center fw-bold" style={{fontSize: '45px', color: 'white'}}>Obstacle Detection<br/>
                                    &<br/>
                                    Alerting System for Pedestrains</div>
      </div>
      <Affiliatecard />
      {/* <ImageCard /> */}
     <br/>
      <Footer />
    </div>
  );
}
