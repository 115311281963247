import React from 'react';
import backgroundVideo from '../assets/Ivalk.mp4';

export default function HomeBackground() {
  return (
    <div className="relative overflow-hidden bg-gray-900 h-screen w-screen">
      <video
        autoPlay
        loop
        muted
        className="absolute inset-0 w-full h-full object-cover" style={{height: '852px'}}
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
     
    </div>
  );
}
