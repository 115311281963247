import React from 'react';

export default function Signup({ onClose }) {
  return (
    <div className="signup-popup">
      <h2>Signup</h2>
      <form>
        <div className="form-group">
          <label>Username:</label>
          <input type="text" className="form-control" />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" className="form-control" />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input type="password" className="form-control" />
        </div>
        <button type="submit" className="btn btn-primary">Signup</button>
      </form>
      <button onClick={onClose} className="btn btn-secondary mt-3">Close</button>
    </div>
  );
}
