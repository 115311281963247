import React from 'react';
import aboutbg from '../assets/aboutbg.png';

export default function AboutText() {
  return (
    <section id="about" className="about section">
      <div className="container mt-5 mb-5">
        <div className="row gx-0">
          <div className="col-lg-6  d-flex flex-column justify-content-center" data-aos-delay="200"  >
            <div className="content" style={{backgroundColor: 'lightgrey', padding: '40px'}}>
              <h3 className="mt-2 mb-2">IValk: Your Obstacle-Aware Phone Companion</h3>
              <h6>Ivalk is a groundbreaking app that uses your phone's rear camera to detect obstacles while you walk and text, keeping you safe. </h6>
              <h6>This discreet background app constantly scans your surroundings, alerting you with subtle vibrations or sounds when an obstruction is detected.
                     With Ivalk, you can navigate crowded streets and unfamiliar environments worry-free.</h6>
              <h6>The app's intuitive settings allow you to customise sensitivity and alerts to your preferences.
                  By harnessing your phone's camera and intelligent software, Ivalk empowers you to multitask safely. </h6>
              <h6>Stay focused on your digital tasks while maintaining situational awareness, thanks to this reliable obstacle detection system in your pocket.</h6>
              <h6>Download Ivalk today and experience the peace of mind that comes with having an extra set of eyes watching your back as you walk and use your phone.
              </h6>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center" data-aos-delay="200">
            <img src={aboutbg} className="img-fluid" alt="About background" />
          </div>
        </div>
      </div>
    </section>
  );
}
