import React from 'react';

export default function Footer() {
  return (
    <footer id="footer" className="footer" style={{ backgroundColor: '#050325' }}>
      <div className="container footer-top" id="contact">
        <div className="row gy-4 mb-5">
          <div className="col-lg-5 col-md-6 footer-about">
            <a href="index.php" className="d-flex align-items-center">
              <span className="sitename" style={{ color: 'white', fontWeight: 700, fontSize: '31px', textDecoration: 'none' }}>iVALK</span>
            </a>
            <div className="footer-contact pt-3">
              <div className="social-links d-flex">
                <a href="https://x.com/iValk_" style={{ fontSize: '23px', color: 'white', marginRight: '19px' }}><i className="bi bi-twitter-x"></i></a>
                <a href="https://www.facebook.com/ivalk.net/" style={{ fontSize: '23px', color: 'white', marginRight: '19px' }}><i className="bi bi-facebook"></i></a>
                <a href="https://www.instagram.com/" style={{ fontSize: '23px', color: 'white', marginRight: '19px' }}><i className="bi bi-instagram"></i></a>
                <a href="https://www.linkedin.com/" style={{ fontSize: '23px', color: 'white' }}><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-3 footer-links">
            <ul>
              <li><a href="/">About us</a></li>
              <li><a href="/">Contact Us</a></li>
              <li><a href="/">Careers</a></li>
              <li><a href="/">Press</a></li>
            </ul>
          </div>

          <div className="col-lg-2 col-md-3 footer-links">
            <ul>
              <li><a href="/">Features</a></li>
              <li><a href="/">Pricing</a></li>
              <li><a href="/">News</a></li>
              <li><a href="/">Help Desk</a></li>
              <li><a href="/">Support</a></li>
            </ul>
          </div>

          <div className="col-lg-2 col-md-3 footer-links">
            <ul>
              <li><a href="/">Privacy Policy</a></li>
              <li><a href="/">Terms & Condition</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container copyright text-center mt-4">
        <p style={{ color: 'white' }}>© <span>Copyright</span> <strong className="px-1 sitename">iVALK</strong> <span>All Rights Reserved</span></p>
        <div className="credits" style={{ color: 'white' }}>
          Designed by <a href="https://veramasa.com/">Veramasa</a>
        </div>
      </div>

      {/* Scroll Top */}
      {/* <a href="/" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </a> */}
    </footer>
  );
}
